import React, {useContext} from 'react';
import LangContext from '../components/context/langContext';
import InterfaceBlock from '../components/interfaces/InterfaceBlock';
import Usecases from '../components/interfaces/Usecases';
import Layout from '../components/template/layout';
import { GetInterfacesSeoEN } from '../content/pages/actions/interfaces/seo/en/getInterfaceSeo';
import { GetInterfacesSeoFR } from '../content/pages/actions/interfaces/seo/fr/getInterfaceSeo';

const Interfaces = () => {
    const ctx = useContext(LangContext)
    const content = ctx.lang === 'fr' ? GetInterfacesSeoFR() : GetInterfacesSeoEN()

    return (
        <Layout
            title={content.pageTitle}
            description={content.pageDescription}
            lang={ctx.lang}
            page="interface"
        >
            <InterfaceBlock
                lang={ctx.lang}
            />
            <Usecases 
                lang={ctx.lang}
            />
        </Layout>
    );
};

export default Interfaces;