import React from 'react';
import { GetUseCasesEN } from '../../content/pages/actions/interfaces/useCasesBlock/en/GetUseCases';
import { GetUseCasesFR } from '../../content/pages/actions/interfaces/useCasesBlock/fr/GetUseCases';
import Img from 'gatsby-image';
import './usecases.scss'
const Usecases = ({lang}) => {
    const content = lang === 'fr' ? GetUseCasesFR() : GetUseCasesEN()
    return (
        <div className="content-container">
            <h2
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >{content.useCaseTitle}</h2>
            <div className="usecases">
                {content.useCase.map((e,i) =>  (
                    <div key={i}>
                        <p className="usecase-title">{e.title}</p>
                        <p className="usecase-colab">{e.colab}</p>
                        <p className="usecase-date">{e.launchDate}</p>
                        <Img fixed={e.image.childImageSharp.fixed} />
                        {e.content.map((e,i)=> (
                           <p>
                               {e}
                            </p> 
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Usecases;