import React from 'react';
import { GetGatewaysBlockEN } from '../../content/pages/actions/interfaces/gatewaysBlock/en/GetGateWays';
import { GetGatewaysBlockFR } from '../../content/pages/actions/interfaces/gatewaysBlock/fr/GetGateWays';
import Img from  'gatsby-image';
import './interface.scss';

const InterfaceBlock = ({lang}) => {
    const content = lang === 'fr' ? GetGatewaysBlockFR() : GetGatewaysBlockEN()

    return (
        <div className="content-container gateways">
            <h2   
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease">
                {content.gateways}</h2>
            <p>{content.gatewaysContentTop}</p>
            <Img fixed={content.gatewaysSchema.childImageSharp.fixed} />
            {content.gatewaysContent.map((e,i)=>(
                <p key={i}>{e}</p>
            ))}
            <button>{content.gatewaysContact}</button>
        </div>
    );
};

export default InterfaceBlock;